<template>
  <div class="h-box-shadow">
    <el-table
      v-loading="table.loading"
      :data="table.data"
      max-height="500px"
      height="400px"
      size="medium"
      stripe
      tooltip-effect="light">
      <el-table-column
        prop="Sample_ID"
        min-width="180"
        label="Sample ID"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_Name"
        min-width="180"
        label="Sample Name"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_fractions"
        min-width="180"
        label="Sample fractions"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Li"
        min-width="180"
        label="Li"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Be"
        min-width="180"
        label="Be"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="B"
        min-width="180"
        label="B"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="K"
        min-width="180"
        label="K"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sc"
        min-width="180"
        label="Sc"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="V"
        min-width="180"
        label="V"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Cr"
        min-width="180"
        label="Cr"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Co"
        min-width="180"
        label="Co"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ni"
        min-width="180"
        label="Ni"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Cu"
        min-width="180"
        label="Cu"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Zn"
        min-width="180"
        label="Zn"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Gu"
        min-width="180"
        label="Gu"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ge"
        min-width="180"
        label="Ge"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Rb"
        min-width="180"
        label="Rb"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sr"
        min-width="180"
        label="Sr"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Y"
        min-width="180"
        label="Y"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Zr"
        min-width="180"
        label="Zr"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Nb"
        min-width="180"
        label="Nb"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Cs"
        min-width="180"
        label="Cs"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ba"
        min-width="180"
        label="Ba"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="La"
        min-width="180"
        label="La"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ce"
        min-width="180"
        label="Ce"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Pr"
        min-width="180"
        label="Pr"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Nd"
        min-width="180"
        label="Nd"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sm"
        min-width="180"
        label="Sm"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Eu"
        min-width="180"
        label="Eu"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Gd"
        min-width="180"
        label="Gd"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Tb"
        min-width="180"
        label="Tb"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Dy"
        min-width="180"
        label="Dy"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ho"
        min-width="180"
        label="Ho"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Er"
        min-width="180"
        label="Er"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Tm"
        min-width="180"
        label="Tm"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Yb"
        min-width="180"
        label="Yb"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Lu"
        min-width="180"
        label="Lu"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Hf"
        min-width="180"
        label="Hf"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ta"
        min-width="180"
        label="Ta"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Tl"
        min-width="180"
        label="Tl"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Pb"
        min-width="180"
        label="Pb"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Bi"
        min-width="180"
        label="Bi"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Th"
        min-width="180"
        label="Th"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="U"
        min-width="180"
        label="U"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Os"
        min-width="180"
        label="Os"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ir"
        min-width="180"
        label="Ir"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ru"
        min-width="180"
        label="Ru"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Rh"
        min-width="180"
        label="Rh"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Pt"
        min-width="180"
        label="Pt"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Pd"
        min-width="180"
        label="Pd"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Re"
        min-width="180"
        label="Re"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ag"
        min-width="180"
        label="Ag"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Al"
        min-width="180"
        label="Al"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="As"
        min-width="180"
        label="As"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Au"
        min-width="180"
        label="Au"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ca"
        min-width="180"
        label="Ca"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Cd"
        min-width="180"
        label="Cd"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Fe"
        min-width="180"
        label="Fe"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Hg"
        min-width="180"
        label="Hg"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="In"
        min-width="180"
        label="In"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Mg"
        min-width="180"
        label="Mg"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Mn"
        min-width="180"
        label="Mn"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Mo"
        min-width="180"
        label="Mo"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Na"
        min-width="180"
        label="Na"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="P"
        min-width="180"
        label="P"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="S"
        min-width="180"
        label="S"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Si"
        min-width="180"
        label="Si"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sb"
        min-width="180"
        label="Sb"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Se"
        min-width="180"
        label="Se"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sn"
        min-width="180"
        label="Sn"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Tc"
        min-width="180"
        label="Tc"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Te"
        min-width="180"
        label="Te"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Ti"
        min-width="180"
        label="Ti"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="W"
        min-width="180"
        label="W"
        show-overflow-tooltip>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import dMicronutrientApi from '@/api/dMicronutrient'
export default {
  name: 'Search_Detail_dMicronutrient',
  data() {
    return {
      sampleId: this.$route.query.sampleId,
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 100,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      if (!this.sampleId) {return}
      this.table.loading = true
      dMicronutrientApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        wheres: `[
          {"name":"Sample_ID","value":"${this.sampleId}","displayType":"number"}
        ]`.replace(/\s+/g,"")
      }).then(res => {
        this.table.loading = false
        this.table.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.talbe.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-pagination {
    margin: 12px 0;
  }
</style>
